import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityCell from "./lib/ActivityCell";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IconCell from "./lib/IconCell";
import NameCell from "./lib/NameCell";
import React, { useCallback, useMemo } from "react";
import TableNew, { TableHead, TableRow } from "lib/common/TableNew";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const { LS_KEYS: { TASKS_VIEW_LAST_TAB } } = Constants;

const { STATUSES: { TO_REVIEW } } = DataConstants;

const SummaryTable = ({ data }) => {
  const [{ sortBy, sortOrder }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const tasksViewLastTab = Utils.storageValue(TASKS_VIEW_LAST_TAB) || "list";

  const hasBusinessUsers = data.some(({ business: { id: businessId } }) => {
    return usersData.some(({ role, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && businessIds.includes(businessId);
    });
  });

  const sorted = useMemo(() => {
    switch (sortBy) {
      case "tasks.forBusinesses":
      case "tasks.forAccountants":
      case "transactions.exported":
        return Utils.arraySort(data, (item) => {
          const { current = 0, total = 0 } = Utils.getPropertyByPath(item, sortBy) || {};

          return total ? current / total : -1;
        }, sortOrder === "asc");
      case "lastActivityEvent":
        return Utils.arraySort(data, (item) => {
          return Utils.getPropertyByPath(item, "lastActivityEvent.timestamp") || "";
        }, sortOrder !== "asc");
      default:
        return Utils.arraySort(data, (item) => {
          return Utils.getPropertyByPath(item, sortBy);
        }, sortOrder === "asc");
    }
  }, [data, sortBy, sortOrder]);

  const handleSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  return (
    <TableNew
      theme="flat"
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortChange={handleSortChange}
      className={Css.table}>
      <TableRow className={Css.row}>
        <TableHead
          className={classNames(Css.head, Css.nameCell)}
          accessor="business.name">
          {uiTexts.business}
        </TableHead>
        <TableHead
          title={uiTexts.documentsToReview}
          className={classNames(Css.head, Css.iconCell)}
          accessor="documents.toReview.current">
          {uiTexts.documentsToReview}
        </TableHead>
        <TableHead
          title={uiTexts.tasksForAccountant}
          className={classNames(Css.head, Css.iconCell)}
          accessor="tasks.forAccountants"
          show={hasBusinessUsers}>
          {uiTexts.tasksForAccountant}
        </TableHead>
        <TableHead
          title={uiTexts.tasksForClient}
          className={classNames(Css.head, Css.iconCell)}
          accessor="tasks.forBusinesses"
          show={hasBusinessUsers}>
          {uiTexts.tasksForClient}
        </TableHead>
        <TableHead
          title={uiTexts.recentActivity}
          accessor="lastActivityEvent"
          className={classNames(Css.head, Css.activityCell)}>
          {uiTexts.recentActivity}
        </TableHead>
      </TableRow>
      {sorted.map((item) => {
        const { business: { id: businessId, name, extraData = {}, settings }, lastActivityEvent } = item;

        const { DOCUMENTS, TASKS } = UiRoutes;

        const currentBusinessHasBusinessUsers = usersData.some(({ role, businessIds }) => {
          return UserRoles.checkIsBusiness(role) && businessIds.includes(businessId);
        });

        return (
          <TableRow key={businessId} className={Css.row}>
            <NameCell
              className={classNames(Css.nameCell)}
              name={name}
              businessId={businessId}
              extraData={extraData}
              settings={settings} />
            <IconCell
              className={Css.iconCell}
              theme="primary"
              iconComponent={Icons.FileText}
              value={Utils.getPropertyByPath(item, "documents.toReview.current")}
              href={`/${businessId}${DOCUMENTS}?${Utils.objectToQueryString({ status: TO_REVIEW })}`} />
            <IconCell
              progress
              show={hasBusinessUsers}
              emptyState={!currentBusinessHasBusinessUsers}
              className={Css.iconCell}
              theme="success"
              iconComponent={Icons.ChatCircleText}
              value={Utils.getPropertyByPath(item, "tasks.forAccountants")}
              href={`/${businessId}${TASKS}/${tasksViewLastTab}?targetUser=accountant`} />
            <IconCell
              progress
              show={hasBusinessUsers}
              emptyState={!currentBusinessHasBusinessUsers}
              className={Css.iconCell}
              theme="accent"
              iconComponent={Icons.Lightning}
              value={Utils.getPropertyByPath(item, "tasks.forBusinesses")}
              href={`/${businessId}${TASKS}/${tasksViewLastTab}?targetUser=client`} />
            <ActivityCell
              businessId={businessId}
              lastActivityEvent={lastActivityEvent}
              className={Css.activityCell} />
          </TableRow>
        );
      })}
    </TableNew>
  );
};

export default React.memo(SummaryTable);
